import React from "react";
import { LogoDark } from "../assets";
import { GrInstagram } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebook, FaLinkedin, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="px-4 py-10 bg-white">
      <div className="mx-auto flex max-w-[85rem]  ">
        <div className="flex flex-col items-center w-full mx-auto gap-y-4 text-[#003862] lg:gap-y-0 text-center text-sm  lg:flex-row justify-between lg:text-left">
          <a href="/">
            {/* Logo */}
            <div className="h-7 my-4 text-heading">
              <img className="w-24" src={LogoDark} alt="" />
            </div>
          </a>
          <a
            rel="noopener noreferrer"
            href="https://maps.app.goo.gl/oL4cT6rwWVe8DuJg8"
            className="font-medium text-sm"
          >
            East Legon - Nii Afutu <br /> Tsuru Street GD-185-4362
          </a>
          <div className=" ">
            <a
              href="tel:+233553666636"
              className="font-medium text-sm hover:text-heading"
            >
              +233 553 666 636
            </a>
            <br />{" "}
            <a
              href="tel:+233302517446"
              className="font-medium text-sm hover:text-heading"
            >
              +233 302 517 446{" "}
            </a>{" "}
            <br />{" "}
            <a
              href="tel:+233559689972"
              className="font-medium text-sm hover:text-heading"
            >
              +233 55 968 9972
            </a>
          </div>
          <Link
            to="/privacy-policy"
            className="font-medium text-sm hover:text-heading"
          >
            Privacy Policy
          </Link>
          <div>
            <div className="flex space-x-2">
              <a
                rel="noopener noreferrer"
                href="https://www.instagram.com/banbobrokers?igsh=ZmszMHRsd2hzczlz"
              >
                <GrInstagram className="text-2xl" />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://x.com/banbobrokers?t=zMR_-Ceun4pF91G3bZabqg&s=08"
              >
                <BsTwitterX className="text-2xl" />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.facebook.com/banbobrokers"
              >
                <FaFacebook className="text-2xl" />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/banbo/"
              >
                <FaLinkedin className="text-2xl" />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@banboinsurancebrokersltd"
              >
                <FaTiktok className="text-2xl" />
              </a>
            </div>
          </div>
          {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
