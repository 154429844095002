import React from "react";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { FaWhatsapp } from "react-icons/fa";

const Hero = () => {
  const slidesData = [
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609758/carousel1_fp0bk6.webp",
      title:
        "We lead in insurance broking services, leveraging cutting-edge technology.",
      description:
        "We ensure guaranteed peace of mind for individuals and organizations through robust financial security.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609759/carousel2_bzl3tv.webp",
      title:
        "Our dedication to financial inclusion and responsible business practices drives us.",
      description:
        "We aim to make insurance accessible, transparent, and specifically tailored for the modern era.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609759/carousel3_rbr3he.webp",
      title:
        "We are committed to fostering a financially confident and resilient community.",
      description:
        "Our agile insurance broking strategies deliver swift, personalized solutions tailored to meet your specific needs.",
    },
  ];
  return (
    <div className="h-[100vh] w-full">
      <Swiper
        spaceBetween={30}
        loop={true}
        effect={"fade"}
        navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="bg-gradient-to-t from-[#003862] flex h-[100vh] items-end w-full">
                <section className="px-4 lg:px-0 py-16 mx-auto max-w-[90rem]">
                  <div className="w-full px-4 sm:px-6  lg:w-8/12 ">
                    <h1
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-duration="1000"
                      data-aos-once="true"
                      data-aos-delay="200"
                      className="mb-3 text-3xl font-semibold leading-tight text-white md:text-4xl lg:text-5xl xl:text-6xl"
                    >
                      {slide.title}
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-duration="1000"
                      data-aos-once="true"
                      data-aos-delay="400"
                      className="mb-5 text-base text-white xl:text-lg"
                    >
                      {slide.description}
                    </p>
                    <div
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-duration="1000"
                      data-aos-once="true"
                      data-aos-delay="600"
                      className="flex flex-wrap gap-x-2 gap-y-4 items-center"
                    >
                      <a
                        href="https://wa.me/233553666636"
                        target="_blank"
                        rel="noreferrer"
                        className="relative inline-flex items-center justify-start px-14 py-4 overflow-hidden font-bold rounded-full group"
                      >
                        <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
                        <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-8"></span>
                        <span className="relative w-full flex gap-x-2 items-center text-left text-white transition-colors duration-200 ease-in-out group-hover:text-gray-900">
                          <FaWhatsapp /> Let's talk
                        </span>
                        <span className="absolute inset-0 border-2 border-white rounded-full"></span>
                      </a>
                      <div className="animate-text-shimmer bg-clip-text text-transparent bg-[linear-gradient(110deg,#e2e8f0,45%,#1e293b,55%,#e2e8f0)] bg-[length:250%_100%]"></div>
                      <button className="inline-flex items-center justify-center h-16 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-gray-600 border-solid rounded-full cursor-pointer select-none hover:text-white hover:border-white focus:shadow-xs focus:no-underline">
                        Dial *718*7#
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="absolute bottom-5 text-white z-30 font-light right-5 flex space-x-2">
        <VscArrowLeft className="text-5xl lg:text-6xl arrow-left arrow border-2 border-white p-2 cursor-pointer hover:bg-[#D1EAFE] hover:text-[#003862] rounded-full transition" />
        <VscArrowRight className="text-5xl lg:text-6xl arrow-left arrow border-2 border-white p-2 cursor-pointer hover:bg-[#D1EAFE] hover:text-[#003862] rounded-full transition" />
      </div>
    </div>
  );
};

export default Hero;
