const QuoteFormGroup = ({ fields }) => {
  return (
    <div className="lg:col-span-2 my-auto">
      <form>
        <div className="grid gap-5 lg:grid-cols-2 my-4">
          {fields.map((field, index) => (
            <div className="space-y-2" key={index}>
              <label
                htmlFor={field.name}
                className="inline-block text-sm font-medium text-[#003862] mt-2.5"
              >
                {field.label}
              </label>
              {field.type === "text" && (
                <input
                  id={field.name}
                  type="text"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "email" && (
                <input
                  id={field.name}
                  type="email"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "date" && (
                <input
                  id={field.name}
                  type="date"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "number" && (
                <input
                  id={field.name}
                  type="number"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "file" && (
                <input
                  id={field.name}
                  type="file"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "select" && field.options && (
                <select
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <option selected disabled value="">
                    Select an option
                  </option>
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>
              )}
              {field.type === "textarea" && (
                <textarea
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
            </div>
          ))}
        </div>
        <div className="my-5 flex">
          <input
            type="checkbox"
            required
            className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
            id="af-submit-application-privacy-check"
          />
          <label
            htmlFor="af-submit-application-privacy-check"
            className="text-sm text-gray-700 ms-2"
          >
            I/We, the undersigned, do hereby warrant the truth and correctness
            of all above statements and particulars and I/We declare that I/We
            have not withheld any material Information. I/We agree that the
            above proposal and this warranty and declaration shall be the basis
            of the Contract between me/us, and Banbo Insurance Brokers and I/We
            agree to abide by the terms and conditions of the Policy issued in
            answer to this Proposal. I authorize Banbo to renew my policy
            annually on my behalf.
            <br />
            <br />
            <span className="font-bold">Important Note:</span> No liability is
            undertaken until the underwriters accept the proposal and the
            Premium is paid.
          </label>
        </div>
      </form>
    </div>
  );
};

export default QuoteFormGroup;
