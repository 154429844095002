import React from "react";
import { Disclosure } from "@headlessui/react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidMinusCircle } from "react-icons/bi";

const PrivacyPolicy = () => {
  return (
    <div>
      <div>
        <div className="max-w-5xl px-4 pt-32 pb-12 sm:px-6 lg:px-8 mx-auto">
          <div className="space-y-5 md:space-y-8 text-[#003862]">
            <div className="space-y-3">
              <h2 className="text-3xl font-bold md:text-3xl ">
                Privacy Policy
              </h2>

              <p className="text-base font-medium">
                Banbo Insurance Brokers Ltd (hereinafter referred to as the
                Company) operates a software solution known for its website
                (https://banbo.insure). This information explains what
                information the Company collects when you use the ‘Banbo App’,
                the Company’s sites, services, contents and products (all
                hereinafter referred to as Service). This page outlines how the
                Company stores, uses, transfers and deletes information. The
                Company aims at all material times to comply with all applicable
                laws as regards privacy, data protection and the protection of
                the interest of the User. By using the Company’s services, you
                agree to the collection and use of information in accordance
                with this policy. If you do not agree with this policy, do not
                access or use our Services.
              </p>
            </div>

            <Disclosure
              as="div"
              className="border-b-2 py-10"
              defaultOpen={true}
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg  text-2xl font-bold ">
                    <span>INFORMATION COLLECTION & USE</span>
                    {open ? (
                      <BiSolidMinusCircle className="text-[#1A9AFB] text-5xl duration-500" />
                    ) : (
                      <AiOutlinePlusCircle className="text-[#1A9AFB] text-5xl duration-500" />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className=" pt-4 pb-2 ">
                    <p className="text-base font-medium mb-5">
                      While using the Company’s services, we may ask you to
                      provide us with certain personally identifiable
                      information that can be used to contact or identify you.
                      Such information includes but is not limited to your name,
                      mobile money wallet number, tax identification number
                      (TIN), national ID number, postal address, residential
                      address, occupation etc. We collect information about you
                      when you register for an account, create or modify your
                      profile, subscribe to insurance policies from an Insurance
                      Company using our services. You are at liberty to review
                      any information in using our service.
                    </p>
                    <p className="text-base font-medium mb-5">
                      We keep track of your preferences when you update settings
                      within the Services. The Company collects information from
                      your content to us when you provide feedback or report on
                      problems you are experiencing from the use of the
                      Company’s Services or when you participate in any
                      interactive features, surveys, promotions, activities or
                      events.
                    </p>
                    <p className="text-base font-medium mb-5">
                      Generally, we collect information on any action you take
                      using the Company’s services.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Payment Information
                    </h1>
                    <p className="text-base font-medium mb-5">
                      When you subscribe to insurance policies from Insurance
                      Companies through the Company’s services, the Company
                      collects your payment information through the electronic
                      mediums you choose. However, the Company does not collect
                      any information as regards your Mobile Money PIN.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Device and Connection Information
                    </h1>
                    <p className="text-base font-medium mb-5">
                      We collect information about your phone, tablet, or other
                      devices you use to access the Services. This device
                      information includes your connection type and settings
                      when you install, access, update, or use our Services. We
                      may also collect information through your device about
                      your operating system, IP address, URLs of referring/exit
                      pages, device identifiers, and crash data. How much of
                      this information we collect depends on the type and
                      settings of the device you use to access the Services.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="border-b-2 py-10">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg text-2xl font-bold">
                    <span>USE OF THE INFORMATION WE COLLECT</span>
                    {open ? (
                      <BiSolidMinusCircle className="text-[#1A9AFB] text-5xl" />
                    ) : (
                      <AiOutlinePlusCircle className="text-[#1A9AFB] text-5xl" />
                    )}
                  </Disclosure.Button>

                  <Disclosure.Panel className=" pt-4 pb-2 ">
                    <p className="text-base font-medium mb-3">
                      We use the information collected to improve and maximize
                      your use of the Company’s services. We use information
                      about you to provide the services to you, including to
                      process transactions, authenticate you when you sign in,
                      provide customer support, and operate, maintain, and
                      improve the Services.
                    </p>
                    <p className="text-base font-medium mb-3">
                      E.g. The feedback provided by you will be used to improve
                      our Services and aid the insurance companies to develop
                      insurance products to meet your personalized experience
                      and to meet changing times.
                    </p>
                    <p className="text-base font-medium mb-3">
                      We forward the data provided to our partner Insurance
                      Companies that provide the insurance policies which form
                      the content of our services.
                    </p>
                    <p className="text-base font-medium mb-3">
                      The Company also uses your contact information to send
                      transactional communications via e-mail and within the
                      services, including confirming your subscriptions to
                      insurance policies, reminding you of subscription
                      expirations, responding to your comments, questions and
                      requests, providing customer support, and sending you
                      technical notices, updates, security alerts, and
                      administrative messages.
                    </p>
                    <p className="text-base font-medium mb-3">
                      We use your information to resolve technical issues you
                      encounter, to respond to your requests for assistance, to
                      analyze crash information, and to repair and improve the
                      Services. Where you give us express permission to do so,
                      we share information with a third-party expert for the
                      purpose of responding to support-related requests.
                    </p>
                    <p className="text-base font-medium mb-3">
                      We use information about you and your service usage to
                      verify accounts and activity, to detect, prevent, and
                      respond to potential or actual security incidents and to
                      monitor and protect against other malicious, deceptive,
                      fraudulent or illegal activity, including violations of
                      Service policies.
                    </p>
                    <p className="text-base font-medium mb-5">
                      Where required by law or where we believe it is necessary
                      to protect our legal rights, interests and the interests
                      of others, we use information about you in connection with
                      legal claims, compliance, regulatory, and audit functions,
                      and disclosures in connection with the acquisition, merger
                      or sale of a business.
                    </p>
                    <h1 className="text-xl font-bold mb-5">Communications</h1>
                    <p className="text-base font-medium mb-3">
                      We may use your Personal Information to contact you with
                      marketing or promotional materials from our partner
                      Insurance Companies in respect of new insurance policies
                      or education as regards existing insurance policies and
                      how you can take full benefit of such policies. We collect
                      information about your phone, tablet, or other devices you
                      use to access the Services. This device information
                      includes your connection type and settings when you
                      install, access, update, or use our Services. We may also
                      collect information through your device about your
                      operating system, IP address, URLs of referring/exit
                      pages, device identifiers, and crash data. How much of
                      this information we collect depends on the type and
                      settings of the device you use to access the Services.
                    </p>
                    <p className="text-base font-medium mb-5">
                      We share information with third parties that help us
                      operate, provide, improve, integrate, customize, support
                      and market our Services.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Service Providers
                    </h1>
                    <p className="text-base font-medium mb-5">
                      We work with third-party service providers to provide
                      website and application development, hosting, maintenance,
                      backup, storage, virtual infrastructure, payment
                      processing, analysis and other services for us, which may
                      require them to access or use information about you. If a
                      service provider needs to access information about you to
                      perform services on our behalf, they do so under close
                      instruction from us, including appropriate security and
                      confidentiality procedures designed to protect your
                      information
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Compliance with Enforcement Requests and Applicable Laws;
                      Enforcement of Our Rights:
                    </h1>
                    <p className="text-base font-medium mb-5">
                      Where it is necessary, the Company may share information
                      in compliance with a court order, legal process or any
                      regulation and also for the Company to enforce its
                      agreements, policies and terms of service, protect the
                      security or integrity of its services. The Company will
                      also share information when necessary to protect itself
                      and its customers or the public from harm or illegal
                      activities and to respond to an emergency which the
                      Company believes in good faith requires the disclosure of
                      information.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Deletion of Account
                    </h1>
                    <p className="text-base font-medium mb-5">
                      Where the Company terminates a User’s access to its
                      services, it deletes data provided by such User within 18
                      months. A User’s account may be deactivated and/or deleted
                      in the event that the use of the App is detrimental to the
                      running of the Service.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Affiliates & Business Partners
                    </h1>
                    <p className="text-base font-medium mb-5">
                      The Company shares information with affiliated companies
                      in order to improve services offered to you. The Company
                      may share or transfer information it collects when you
                      subscribe to its services in connection with any merger,
                      sale of company assets, financing, or acquisition of all
                      or a portion of our business to another company.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Processing of Data
                    </h1>
                    <p className="text-base font-medium mb-3">
                      Subject to the Data Protection Act, 2012 (ACT 843) and all
                      other applicable privacy laws in Ghana, we collect and
                      process information you have provided while using the
                      Company’s Services. The data processed is the information
                      you have voluntarily given to the Company in line with
                      using the Company’s Services.
                    </p>
                    <p className="text-base font-medium mb-3">
                      For the purpose of being a User of the Company’s Services,
                      the Company, or its Affiliates, will transfer data amongst
                      themselves as necessary for the purpose of implementation,
                      administration and management of the App. The Company
                      and/or any of its Affiliates may further transfer data to
                      any third parties assisting the Company in the
                      implementation, administration and management of the App.
                      These various recipients of data may be located elsewhere
                      throughout the world. You authorize these various
                      recipients of data to receive, possess, use, retain and
                      transfer the data, in electronic or other form, for the
                      purposes of implementing, administering and managing the
                      Banbo App.
                    </p>
                    <p className="text-base font-medium mb-5">
                      If you have consented to our use of information about you,
                      you have the right to change your mind at any time, but
                      this will not affect any processing that has already taken
                      place. Where the Company is using your information, you
                      have the right to object to that use though this may mean
                      you no longer use the services.
                    </p>
                    <h1 className="text-xl font-bold mb-5">Security</h1>
                    <p className="text-base font-medium mb-5">
                      The Company takes the required steps to protect your
                      personal information as your security is important.
                      However, it is pertinent to note that no method of
                      transmission over the Internet, or method of electronic
                      storage, is 100% secure. While we strive to use acceptable
                      means to protect your personal Information, we cannot
                      guarantee its absolute security.
                    </p>
                    <h1 className="text-xl font-bold mb-5">
                      Changes to This Privacy Policy
                    </h1>
                    <p className="text-base font-medium mb-3">
                      This Privacy Policy will remain in effect except with
                      respect to any changes in its provisions in the future,
                      which will be in effect immediately after being posted on
                      this page.
                    </p>
                    <p className="text-base font-medium mb-3">
                      The Company reserves the right to update or change this
                      Privacy Policy at any time. Your continued use of the
                      Service after we post any modifications to the Privacy
                      Policy on this page will constitute your acknowledgment of
                      the modifications and your consent to abide and be bound
                      by the modified Privacy Policy.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
